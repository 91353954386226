import React from "react";
import {
  dish1,
  dish2,
  dish3,
  dish4,
  dish5,
  dish6,
  dish7,
} from "../../constant";
import { useDispatch } from "react-redux";
import { SelectedDishDispatch } from "../../redux/actions/AuthActions";

const HomeAllDish = (props) => {
  const dispatch = useDispatch();
  const { setIsOpenModalMenu, dishData, setSelectedDish } = props;

  const SelectedDishHandler = (e, dishIndexData) => {
    e.preventDefault();
    setIsOpenModalMenu(true);
    setSelectedDish(dishIndexData);
    dispatch(SelectedDishDispatch(dishIndexData));
  };
  return (
    <>
      {/* {dishData?.length > 5 ? (
        <div
          className="dish-wrapper dish1-wrapper wow animate__animated animate__bounceIn"
          onClick={(e) => SelectedDishHandler(e, dishData[5])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[5]?.image_path}/${dishData[5]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[5]?.name}</h4>
          </div>
        </div>
      ) : null} */}

      {/* invisible */}
      <div
        className="dish-wrapper dish-wrapper invisible"
        onClick={() => setIsOpenModalMenu(true)}
      >
        <div className="dish-img-wrapper">
          <img src={dish1} className="img-fluid" alt="" />
        </div>
        <div className="dish-content-wrapper">
          <h4></h4>
        </div>
      </div>
      {/* invisible */}

      {/* {dishData?.length > 3 ? (
        <div
          className="dish-wrapper dish2-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[3])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[3]?.image_path}/${dishData[3]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[3]?.name}</h4>
          </div>
        </div>
      ) : null}
      {dishData?.length > 1 ? (
        <div
          className="dish-wrapper dish3-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[1])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[1]?.image_path}/${dishData[1]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[1]?.name}</h4>
          </div>
        </div>
      ) : null}
      {dishData?.length > 0 ? (
        <div
          className="dish-wrapper dish4-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[0])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[0]?.image_path}/${dishData[0]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[0]?.name}</h4>
          </div>
        </div>
      ) : null}
      {dishData?.length > 2 ? (
        <div
          className="dish-wrapper dish5-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[2])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[2]?.image_path}/${dishData[2]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[2]?.name}</h4>
          </div>
        </div>
      ) : null}
      {dishData?.length > 4 ? (
        <div
          className="dish-wrapper dish6-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[4])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[4]?.image_path}/${dishData[4]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[4]?.name}</h4>
          </div>
        </div>
      ) : null}

      {dishData?.length > 6 ? (
        <div
          className="dish-wrapper dish7-wrapper"
          onClick={(e) => SelectedDishHandler(e, dishData[5])}
        >
          <div className="dish-img-wrapper">
            <img
              src={`${dishData[6]?.image_path}/${dishData[6]?.logo}`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="dish-content-wrapper">
            <h4>{dishData[6]?.name}</h4>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

export default HomeAllDish;
