import './App.css';
import Home from './pages/Home';
import './assets/css/style.css'
import PublicRoutes from './routes/PublicRoutes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-loading-skeleton/dist/skeleton.css'
import Test from './pages/Test';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const Tracking_Id = "UA-239789268-1"
ReactGA.initialize(Tracking_Id);

const current = new Date();
  const date = `${current.getDate()}${
    current.getMonth() + 1
  }${current.getFullYear()}`;
  const time = current.getHours() + current.getMinutes() + current.getSeconds();
localStorage.setItem("user_id", date + time);


// const email = rememberMe ? localStorage.getItem("email") : "";
function App() { 
  return (
   <>
    <PublicRoutes />
    <ToastContainer />
    {/* <Test /> */}
   </>
  );
}

export default App;
