
// export const ApiUrl =
// "https://restaurant.developer.demos-clients-websites.com/api/v1" ||
// "http://localhost:4000";





export const ApiUrl =
"https://meal.developer.demos-clients-websites.com/api/v1" ||
"http://localhost:4000";



