import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { cart1, notFound } from "../../constant";
import { GetAllCartData, RemoveById } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

function CartModal(props) {
  const {
    setIsOpenModalCart,
    isOpenModalCart,
    setIsOpenModalCheckout,
    setCartData,
    cartData,
  } = props;
  const UserIdRedux = useSelector((state) => state.AuthReducer.userId);
  const [spinLoad, setSpinLoad] = useState(false);
  const [remove, setRemove] = useState(false);

  const CheckOutHandler = (e) => {
    e.preventDefault();
    setIsOpenModalCart(false);
    setIsOpenModalCheckout(true);
  };

  useEffect(() => {
    setSpinLoad(true);
    let data = {
      user_id: UserIdRedux,
    };
    GetAllCartData(data)
      .then((res) => {
        // console.log("CART DATA", res);
        setCartData(res?.data?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [UserIdRedux, isOpenModalCart, remove]);

  const RemoveCartIdHandler = (e, id) => {
    e.preventDefault();
    let data = {
      cart_id: id,
      user_id: UserIdRedux,
    };
    RemoveById(data)
      .then((res) => {
        // console.log(res);
        setRemove(!remove);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(cartData?.length);

  return (
    <>
      <Modal
        isOpen={isOpenModalCart}
        toggle={() => {
          setIsOpenModalCart(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalCart(false)}
        />

        <div id="MenuModal">
          <div className="modal-body">
            <div className="cart-content">
              <div className="heading-wrapper">
                <h2>MY CART </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Augue neque gravida in fermentum et sollicitudin ac. Magna ac
                  placerat vestibulum lectus mauris ultrices. Nec feugiat nisl
                  pretium fusce id. Sed risus pretium quam vulputate.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <div className="cart-products">
                    {spinLoad ? (
                      <div className="row">
                        <div className="col-md-5 mt-5">
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {cartData?.cart?.length > 0 ? (
                          <>
                            {cartData?.cart?.map((item, index) => {
                              return (
                                <div className="cart-box" key={index}>
                                  <div
                                    className="cancel"
                                    onClick={(e) =>
                                      RemoveCartIdHandler(e, item?.id)
                                    }
                                  >
                                    X
                                  </div>
                                  <div className="img-box">
                                    <figure>
                                      <img
                                        src={`${item?.image_path}/${item?.image}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </figure>
                                  </div>
                                  <div className="txt">
                                    <h4>{item?.name}</h4>
                                    <p>{item?.description}</p>
                                    <h4 className="price">
                                      <span>PRICE :</span>${item?.price}
                                    </h4>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="notFound-container">
                            <img
                              src={notFound}
                              alt="not found"
                              className="image-fluid"
                            />
                            <p className="mt-4">No Record Found</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="order-summary">
                    <div className="summary">
                      <div className="title">
                        <h2>ORDER SUMMARY</h2>
                      </div>
                      <ul className="charges">
                        <li>
                          <span>DELIVERY CHARGES :</span>$
                          {cartData?.delivery_fee}
                        </li>
                        <li>
                          <span>TOTAL :</span>${cartData?.total}
                        </li>
                      </ul>
                      <p>
                        <i>
                          Taxes and discounts will be calculated at checkout
                        </i>
                      </p>
                    </div>
                    <div className="button-group">
                      {/* <button className="btn"><i className="fa fa-angle-right" aria-hidden="true"></i> UPDATE CART</button> */}

                      <button
                        className="btn"
                        type="button"
                        onClick={(e) => CheckOutHandler(e)}
                        disabled={cartData?.length < 1}
                      >
                        <i
                          className="fa fa-angle-right mr-2"
                          aria-hidden="true"
                        ></i>{" "}
                        CHECKOUT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CartModal;
