import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { notFound, smallDish } from "../../constant";
import {
  AddToCart,
  GetAllCartData,
  GetAllProducts,
  GetProductsByCountry,
  UpdateCart,
} from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { set } from "react-ga";
import { AiFillCheckCircle, AiFillCaretDown } from "react-icons/ai";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { SpinnerCircular } from "spinners-react";
import { FiDelete } from "react-icons/fi";
import { CartData } from "../../redux/actions/AuthActions";

function AllMenuModal(props) {
  const {
    setIsOpenModalAllMenu,
    isOpenModalAllMenu,
    setIsOpenModalAllMenuDetails,
    flagSelect,
    setFlagSelect,
    CountriesReduxData,
    setEditFilterMeal,
    editFilterMeal,
    setEditStatusMeal,
    editStatusMeal,
    setIsOpenModalContinue,

    selectedStarter, setSelectedStarter,
    selectedMainCourse, setSelectedMainCourse,
  } = props;
  const cartDataRedux = useSelector((state) => state.AuthReducer.cartData);
  const dispatch = useDispatch()
  const User_id = localStorage.getItem("user_id");

  const [menuData, setMenuData] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);
  const [hoverCol, setHoverCol] = useState(false);
  const [hideShow, setHideShow] = useState("");

  // const [selectedStarter, setSelectedStarter] = useState();
  // const [selectedMainCourse, setSelectedMainCourse] = useState();

  const [starterData, setStarterData] = useState([]);
  const [mainCourseData, setMainCourseData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [countryName, setCountryName] = useState();
  const [loadMore, settLoadMore] = useState(false);
  const [count, setCount] = useState(0);

  // const [right, setRight] = useState(true);
  // const [left, setLeft] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(4);
  const [tempFlagSelect, setTempFlagSelect] = useState();

  useEffect(() => {
    setSelectedStarter(editFilterMeal ? editFilterMeal[0]?.starter : null);
    setSelectedMainCourse(
      editFilterMeal ? editFilterMeal[0]?.main_course : null
    );
    setTempFlagSelect(flagSelect)
  }, [isOpenModalAllMenu]);

  useEffect(() => {
    setSpinLoading(true);
    let countryFilt = CountriesReduxData?.filter(
      (item) => item?.id == flagSelect
    );
    setCountryName(countryFilt);
    let data = {
      country_id: flagSelect,
      type: "Starter",
      currentPage: currentPage,
    };
    GetProductsByCountry(data)
      .then((res) => {
        // console.log(res);
        setStarterData(res?.data?.data?.list);
        const total = res?.data?.data?.pagination?.total;
        setSpinLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoading(false);
      });
  }, [currentPage, flagSelect, tempFlagSelect]);

  useEffect(() => {
    setSpinLoading(true);
    let data = {
      country_id: flagSelect,
      type: "Main Course",
      currentPage: currentPage,
    };
    GetProductsByCountry(data)
      .then((res) => {
        // console.log(res);
        setMainCourseData(res?.data?.data?.list);
        setSpinLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoading(false);
      });
  }, [currentPage, flagSelect, tempFlagSelect]);

  const AddToCartHandler = (e) => {
    e.preventDefault();
    if (!selectedStarter || !selectedMainCourse) {
      toast.info("Please Make a Meal");
    }
    let data = {
      starter_product_id: selectedStarter?.id,
      main_course_product_id: selectedMainCourse?.id,
      user_id: User_id,
    };
    AddToCart(data)
      .then((res) => {
        // console.log(res);
        toast.success("Meal Successfully Added");
          let data = {
            user_id: User_id,
          };
          GetAllCartData(data)
            .then((res) => {
              dispatch(CartData(res?.data?.data));
            })
            .catch((err) => {
              console.log(err);
            });


        setSelectedStarter("");
        setSelectedMainCourse("");
        setIsOpenModalAllMenu(false);
        setIsOpenModalContinue(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateMealHandler = (e) => {
    e.preventDefault();
    if (!selectedStarter || !selectedMainCourse) {
      toast.info("Please Make a Meal");
    }
    let data = {
      starter_product_id: selectedStarter?.id,
      main_course_product_id: selectedMainCourse?.id,
      cart_id: editFilterMeal[0]?.id,
      user_id: User_id,
    };
    UpdateCart(data)
      .then((res) => {
        // console.log(res);
        toast.success("Meal Successfully updated");
        setSelectedStarter("");
        setSelectedMainCourse("");
        setEditStatusMeal(!editStatusMeal);
        setEditFilterMeal("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ContinueHandler = (e) => {
    e.preventDefault();
    setIsOpenModalAllMenu(false);
    setIsOpenModalAllMenuDetails(true);
  };

  const StarterHandler = (e, item) => {
    e.preventDefault();
    setTimeout(() => {
      setSelectedStarter(item);
      toast.success("Entree Added");
      AllDishesHandler(e, "main")
    }, 600);
  };

  const MainCourseHandler = (e, item) => {
    e.preventDefault();
    setTimeout(() => {
      setSelectedMainCourse(item);
      toast.success("Main Course  Added");
      AllDishesHandler(e, "starter")
    }, 600);

  };


  const ScrollApi = (countryFilt, count2) => {
    // if (count2 <= countryFilt?.length - 1) {
    //   settLoadMore(true);
    // }
    settLoadMore(true);
    let data = {
      country_id: countryFilt[parseInt(count2)]?.id,
      type: "Starter",
      currentPage: currentPage,
    };

    let data_m = {
      country_id: countryFilt[parseInt(count2)]?.id,
      type: "Main Course",
      currentPage: currentPage,
    };

    setTimeout(() => {
      GetProductsByCountry(data)
        .then((res) => {
          // console.log(res);
          setStarterData([...starterData, ...res?.data?.data?.list]);
          settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          settLoadMore(false);
        });

      // main
      GetProductsByCountry(data_m)
        .then((res) => {
          // console.log(res);
          setMainCourseData([...mainCourseData, ...res?.data?.data?.list]);
          settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          settLoadMore(false);
        });
    }, 500);
    setCount(count2 + 1);
  };

  const FetchDataNext = (e) => {
    e.preventDefault();
    let countryFilt = [];
    if (countryFilt.length < 1) {
      countryFilt = CountriesReduxData?.filter(
        (item) => item?.id != flagSelect
      );
      // console.log("yes", countryFilt);
      ScrollApi(countryFilt, count);
    } else {
      // console.log("else");
      ScrollApi(countryFilt, count);
    }
    // console.log(count);
  };


  const AllDishesHandler = (e, type_course) => {
    e.preventDefault();
    // settLoadMore(true);
    setTempFlagSelect("")
    // if (total > 0) {
    //   setLimit(limit + 4);
    // }
    let data = {
      type: "Starter",
      currentPage: pageNum,
      // limit: total > 0 ? limit + 4 : limit,
      limit: "",
    };

    let data_m = {
      type: "Main Course",
      currentPage: pageNum,
      // limit: total > 0 ? limit + 4 : limit,
      limit: "",
    };
    setTimeout(() => {
      if(type_course == "starter"){
        GetAllProducts(data)
        .then((res) => {
          // console.log("aaaaa", res);
          setStarterData(res?.data?.data?.list);
          setTotal(res?.data?.data?.pagination?.total);
          // setTotalCount(res?.data?.data?.pagination?.last)
          // settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          // settLoadMore(false);
        });
      }
      else{
      // main
      GetAllProducts(data_m)
        .then((res) => {
          // console.log(res);
          setMainCourseData(res?.data?.data?.list);
          // settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          // settLoadMore(false);
        });
      }
    }, 500);
  };

  const AllMainDishesHandler = (e) => {
    e.preventDefault();
    settLoadMore(true);
    let data_m = {
      type: "Main Course",
      currentPage: pageNum,
      limit: 4,
    };

    setTimeout(() => {
      GetAllProducts(data_m)
        .then((res) => {
          // console.log(res);
          setMainCourseData(res?.data?.data?.list);
          settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          settLoadMore(false);
        });
    }, 500);
  };

  const AllStarterDishesHandler = (e) => {
    e.preventDefault();
    settLoadMore(true);
    let data = {
      type: "Starter",
      currentPage: pageNum,
      limit: 4,
    };

    setTimeout(() => {
      GetAllProducts(data)
        .then((res) => {
          setStarterData(res?.data?.data?.list);
          setTotal(res?.data?.data?.pagination?.total);
          settLoadMore(false);
        })
        .catch((err) => {
          console.log(err);
          settLoadMore(false);
        });
    }, 500);
  };

  const RemoveHandler = (e, type) =>{
    e.preventDefault()
    if(type =="main"){
      setSelectedMainCourse()
    }
    if(type =="starter"){
      setSelectedStarter()
    }
  }



  useEffect(() => {
    if(selectedStarter && selectedMainCourse){
        // setFlagSelect("")
        // settLoadMore(true);
        let data = {
          type: "Starter",
          currentPage: pageNum,
          limit: "",
        };
    
        setTimeout(() => {
          GetAllProducts(data)
            .then((res) => {
              setStarterData(res?.data?.data?.list);
              setTotal(res?.data?.data?.pagination?.total);
              // settLoadMore(false);
            })
            .catch((err) => {
              console.log(err);
              // settLoadMore(false);
            });
        }, 500);


        // settLoadMore(true);
        let data_m = {
          type: "Main Course",
          currentPage: pageNum,
          limit: "",
        };
    
        setTimeout(() => {
          GetAllProducts(data_m)
            .then((res) => {
              setMainCourseData(res?.data?.data?.list);
              // settLoadMore(false);
            })
            .catch((err) => {
              console.log(err);
              // settLoadMore(false);
            });
        }, 500);

    
    }
  }, [selectedStarter, selectedMainCourse])
  

  return (
    <>
      <Modal
        isOpen={isOpenModalAllMenu}
        toggle={() => {
          setIsOpenModalAllMenu(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalAllMenu(false)}
        />

        <div id="courseModal">
          <div className="modal-body">
            <div className="AllMenu-Main-container cart-content">
              <div className="heading-wrapper">
                <h2 className="main-title">
                  {countryName ? countryName[0]?.name : null}
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
              </div>
              <div className="cart-data">
                <div className="text-cont">
                  <p>
                    Items Added: {" "}
                    {selectedStarter && selectedMainCourse
                      ? parseInt(selectedStarter?.price) +
                        parseInt(selectedMainCourse?.price)
                      : selectedStarter && !selectedMainCourse
                      ? selectedStarter?.price
                      : selectedMainCourse && !selectedStarter
                      ? selectedMainCourse?.price
                      : 0}
              
                    $
                  </p>
                </div>
                {selectedStarter ? (
                  <div>
                    <img
                      src={`${selectedStarter?.image_path}/${selectedStarter?.image}`}
                      alt=""
                      className="image-fluid mr-2"
                    />
                  </div>
                ) : null}
                {selectedMainCourse ? (
                  <div>
                    <img
                      src={`${selectedMainCourse?.image_path}/${selectedMainCourse?.image}`}
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                ) : null}
              </div>
              <div className="flags-cont">
                {CountriesReduxData?.map((item, index) => {
                  return (
                    <div
                      className={`${
                        tempFlagSelect == item?.id
                          ? "single-img active-flag"
                          : "single-img"
                      }`}
                      key={index}
                      onClick={() => {setFlagSelect(item?.id); setTempFlagSelect(item?.id)}}
                    >
                      <img
                        src={`${item?.image_path}/${item?.image}`}
                        className="image-fluid"
                      />
                    </div>
                  );
                })}
              </div>

              <div className="selected-items-cont">
                {selectedStarter ? (
                  <div
                    className="selected-items"
                    // onClick={() => setSelectedStarter()}
                    // onClick={(e) => {
                    //   setRight(true);
                    //   setLeft(false);
                    // }}
                  >
                    <span className="mr-4">Entree</span>
                    <img
                      src={`${selectedStarter?.image_path}/${selectedStarter?.image}`}
                      alt=""
                      className="image-fluid mr-2"
                    />
                    <FiDelete
                      className="m-2 menu-delete"
                      onClick={(e) =>RemoveHandler(e,"starter")}
                    />

                   
                  </div>
                ) : null}
                {selectedMainCourse ? (
                  <div
                    className="selected-items"
                    // onClick={() => setSelectedMainCourse()}
                    // onClick={(e) => {
                    //   setLeft(true);
                    //   setRight(false);
                    // }}
                  >
                    <span className="mr-4"> Main Course</span>
                    <img
                      src={`${selectedMainCourse?.image_path}/${selectedMainCourse?.image}`}
                      alt=""
                      className="image-fluid"
                    />
                      <FiDelete
                     className="m-2 menu-delete"
                     onClick={(e) => RemoveHandler(e,"main")}
                    />
                    
                  </div>
                ) : null}
              </div>
              {/* <div
                className={`${
                  selectedStarter && selectedMainCourse ? "hide" : "show"
                }`}
              > */}
              <div
                className="row all-courses"
                // className={`${
                //   hideShow == "main"
                //     ? "row all-courses right_align"
                //     : hideShow == "starter"
                //     ? "row all-courses left_align"
                //     : "row all-courses"
                // }`}
                // onScroll={FetchDataNext}
              >
                <div
                  // className={`${
                  //   right == false && left == false
                  //     ? "col-lg-12"
                  //     : `${
                  //         left == false && right == true
                  //           ? "col-lg-12"
                  //           : "dp-non"
                  //       }`
                  // }`}
                  // className={`${
                  //   left == true && right == false ? "col-lg-12" : "dp-non"
                  // }`}
                  className={`${"col-lg-6"}`}
                >
                  <div
                    // className={`${
                    //   selectedStarter && selectedMainCourse
                    //     ? "hide"
                    //     : "courses show"
                    // }`}
                    className={`${"courses show"}`}
                  >
                    <div className="title-cont">
                      <div className="title-wrapper">
                        <h2
                        // onClick={(e) => setRight(!right)}
                        >
                          Entrée
                        </h2>
                      </div>

                      {/* {left == true && right == false ? ( */}
                        {/* <div className="title-wrapper optionalTitle">
                          <h2
                            // onClick={(e) => {
                            //   setRight(true);
                            //   setLeft(false);
                            // }}
                          >
                            Main Course
                          </h2>
                        </div> */}
                       {/* ) : null} */}
                    </div>

                    <div className="row dish-row doted-right">
                      {spinLoading ? (
                        <div className="row">
                          <div className="col-md-12 mt-5">
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : (
                        <>
                          {starterData?.length > 0 ? (
                            <>
                              {starterData?.map((item, index) => {
                                return (
                                  <div
                                    className={`${
                                      "course-flex col-lg-6 mb-2"
                                    }`}
                                    key={index}
                                    // onMouseEnter={() => {
                                    //   setHoverCol(true);
                                    //   setHideShow("starter");
                                    // }}
                                    // onMouseLeave={() => {
                                    //   setHoverCol(false);
                                    //   setHideShow("");
                                    // }}
                                  >
                                    <div
                                      className={`${
                                        selectedStarter?.id == item?.id
                                          ? "course-box Custom-select"
                                          : "course-box"
                                      }`}
                                    >
                                      <button
                                        type="button"
                                        onClick={(e) => StarterHandler(e, item)}
                                      >
                                        <div
                                          className={`${
                                            hoverCol ? "img_box2" : "img_box"
                                          }`}
                                        >
                                          <figure>
                                            <img
                                              src={`${item?.image_path}/${item?.image}`}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </figure>
                                          <div
                                            className={`${
                                              hoverCol ? "price2" : "price"
                                            }`}
                                          >
                                            <h4>${item?.front_sale_price}</h4>
                                          </div>
                                        </div>
                                      </button>
                                      <div className="content">
                                        <h3>{item?.name}</h3>
                                        <p>{item?.description}</p>
                                        <div className="product-country">
                                          <img
                                            src={`${item?.country?.image_path}/${item?.country?.image}`}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        {/* {selectedStarter?.id == item?.id ? (
                                          <div>
                                            <AiFillCheckCircle className="check-icon" />
                                          </div>
                                        ) : null} */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <div className="notFound-container">
                              {/* <img
                                src={notFound}
                                alt="not found"
                                className="image-fluid"
                              /> */}
                              <p className="mt-4">No Record Found</p>
                              <div style={{ textAlign: "center" }}>
                                <p
                                  className="loadMore"
                                  onWheel={(e) => AllStarterDishesHandler(e)}
                                >
                                  {/* Load More Dishes */}
                                  {/* <br /> */}
                                  <AiFillCaretDown className="down-icon" />
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* {
                    right ? (
                      <BsArrowLeftCircleFill 
                      className="left-right"
                      onClick={(e)=>setRight(!right)}
                    />
                   ) : (
                    <BsArrowRightCircleFill 
                    className="left-right"
                    onClick={(e)=>setRight(!right)}
                  />
                    )
                  } */}
                </div>
                <div
                  // className={`${
                  //   right == false && left == false
                  //     ? "col-lg-12"
                  //     : `${
                  //         left == true && right == false
                  //           ? "col-lg-12"
                  //           : "dp-non"
                  //       }`
                  // }`}
                  // className={`${
                  //   left == false && right == true ? "col-lg-12" : "dp-non"
                  // }`}
                  className={`${ "col-lg-6"}`}>
                  {/* <div className="courses"> */}
                  <div
                    // className={`${
                    //   selectedStarter && selectedMainCourse
                    //     ? "hide"
                    //     : "courses show"
                    // }`}
                    className={`${"courses show"}`}
                  >
                    <div className="title-cont">
                      <div className="title-wrapper">
                        <h2
                        // onClick={(e) => setLeft(!left)}
                        >
                          Main Course
                        </h2>
                      </div>
                      {/* {left == false && right == true ? ( */}
                        {/* <div className="title-wrapper optionalTitle">
                          <h2
                            // onClick={(e) => {
                            //   setLeft(true);
                            //   setRight(false);
                            // }}
                          >
                            Entrée
                          </h2>
                        </div> */}
                      {/* ) : null} */}
                    </div>

                    <div className="row dish-row">
                      {spinLoading ? (
                        <div className="row">
                          <div className="col-md-12 mt-5">
                            <Skeleton count={5} />
                          </div>
                        </div>
                      ) : mainCourseData?.length > 0 ? (
                        <>
                          {mainCourseData?.map((item, index) => {
                            return (
                              <div
                                className={`${
                               "course-flex col-lg-6 mb-2"
                                }`}
                                key={index}
                                // onMouseEnter={() => {
                                //   setHoverCol(true);
                                //   setHideShow("main");
                                // }}
                                // onMouseLeave={() => {
                                //   setHoverCol(false);
                                //   setHideShow("");
                                // }}
                              >
                                <div
                                  className={`${
                                    selectedMainCourse?.id == item?.id
                                      ? "course-box Custom-select"
                                      : "course-box"
                                  }`}
                                >
                                  <button
                                    type="button"
                                    onClick={(e) => MainCourseHandler(e, item)}
                                  >
                                    <div
                                      className={`${
                                        hoverCol ? "img_box2" : "img_box"
                                      }`}
                                    >
                                      <figure>
                                        <img
                                          src={`${item?.image_path}/${item?.image}`}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </figure>
                                      <div
                                        className={`${
                                          hoverCol ? "price2" : "price"
                                        }`}
                                      >
                                        <h4>${item?.front_sale_price}</h4>
                                      </div>
                                    </div>
                                  </button>
                                  <div className="content">
                                    <h3>{item?.name}</h3>
                                    <p>{item?.description}</p>
                                    <div className="product-country">
                                      <img
                                        src={`${item?.country?.image_path}/${item?.country?.image}`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    {/* {selectedMainCourse?.id == item?.id ? (
                                      <div>
                                        <AiFillCheckCircle className="check-icon" />
                                      </div>
                                    ) : null} */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="notFound-container">
                          {/* <img
                            src={notFound}
                            alt="not found"
                            className="image-fluid"
                          /> */}
                          <p className="mt-4">No Record Found</p>
                          <div style={{ textAlign: "center" }}>
                            <p
                              className="loadMore"
                              onWheel={(e) => AllMainDishesHandler(e)}
                            >
                              {/* Load More Dishes */}
                              {/* <br /> */}
                              <AiFillCaretDown className="down-icon" />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {
                  left ? (
                    <BsArrowRightCircleFill 
                    className="right-left"
                    onClick={(e)=>setLeft(!left)}
                  />
                   
                  ) : (
                    <BsArrowLeftCircleFill 
                    className="right-left"
                    onClick={(e)=>setLeft(!left)}
                  />
                  )
                 } */}
                </div>
                {loadMore ? (
                  <div className="loader-container">
                    <SpinnerCircular size="80px" color="#d60012" />
                  </div>
                ) : null}
              </div>
              {/* </div> */}

              {/* <div style={{ textAlign: "center" }}>
                <p className="loadMore" onWheel={(e) => AllDishesHandler(e)}>
                  <AiFillCaretDown className="down-icon" />
                </p>
                
              </div> */}
              <div className="row">
                <div className="col-lg-12 btn-container">
                  {editStatusMeal ? (
                    <button
                      className="btn addtocart-Btn mr-5"
                      onClick={(e) => UpdateMealHandler(e)}
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                      Update Meal
                    </button>
                  ) : (
                    <>
                      {selectedStarter && selectedMainCourse ? (
                        <button
                          className="btn addtocart-Btn mr-5"
                          onClick={(e) => AddToCartHandler(e)}
                        >
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                          Confirm
                        </button>
                      ) : (
                        <>
                          <button
                            className="btn addtocart-Btn mr-5"
                            onClick={(e) => setIsOpenModalAllMenu(false)}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            Add Another Meal
                          </button>
                          <button
                            className="btn addtocart-Btn"
                            onClick={(e) => ContinueHandler(e)}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            Go to Cart
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AllMenuModal;
