import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo, smallDish, toggle } from "../../constant";
import { GrFormView } from 'react-icons/gr';

const Header = (props) => {
  const { setIsOpenModalContact, setIsOpenModalMenu, setIsOpenModalCart,cartData,setIsOpenModalAllMenuDetails } =
    props;
  const [headerToggle, setHeaderToggle] = useState(false);

  const HeaderToggleHandler = () => {
    setHeaderToggle(!headerToggle);
  };

  return (
    <>
      <header id="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-2">
              <div
                className="canvas-icon"
                onClick={() => HeaderToggleHandler()}
              >
                <img src={toggle} alt="" />
              </div>
            </div>
            <div className="col-lg-6 header-logo">
              <Link to="/">
                <img src={logo} className="image-fluid" alt="" />
              </Link>
            </div>
            <div className="col-lg-3">
            <div>
              <div className="cart-data"
                onClick={() => setIsOpenModalAllMenuDetails(true)}
              >
                <div className="text-cont">
                  <p>Cart</p>
                </div>
                {
                  cartData?.cart ? (
                    <>
                   <div>
                        <img  src={`${cartData?.cart[0]?.starter?.image_path}/${cartData?.cart[0]?.starter?.image}`} alt="" className="image-fluid mr-2"/>
                      </div>
                       <div>
                        <img src={`${cartData?.cart[0]?.main_course?.image_path}/${cartData?.cart[0]?.main_course?.image}`} alt="" className="image-fluid mr-2"/>
                      </div> 
                       <span>+{cartData?.cart?.length > 1 ? cartData?.cart?.length-1 : null}</span>
                       {/* <GrFormView /> */}
                    </>
                  ) :(<span>Empty</span>)
                }
                 
              </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${headerToggle ? "mobile-header show" : "mobile-header"}`}
        >
          <div className="cancel" onClick={() => HeaderToggleHandler()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <div className="menu-wrapper">
            <div className="menu-heaading-wrapper">
              <a href="">
                <h2>RESTAURANT</h2>
              </a>
            </div>
            <ul className="mobile-nav">
              {/* <li className="nav-item">
                    <button className="btn" 
                        // data-toggle="modal" 
                        // data-target="#courseModal"
                        onClick={()=>setIsOpenModalMenu(true)}
                    >
                        <span>♦</span> 
                        MENU
                    </button>
                </li> */}
              <li className="nav-item">
                <button
                  type="button"
                  className="btn"
                  // data-toggle="modal"
                  // data-target=".bd-example-modal-lg"
                  onClick={() => setIsOpenModalContact(true)}
                >
                  <span>♦</span> CONTACT
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setIsOpenModalAllMenuDetails(true)}
                >
                  <span>♦</span> Cart
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
