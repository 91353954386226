// import React from 'react'
// import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

// const applicationId="sq0idp-Y0QZQ-Xx-Xx-Xx-Xx";
// const locationId="LID"
// const SquareUpPayment = () => {
//    const cardTokenizeResponseReceived=(token, buyer) => {
//         console.info({ token, buyer });
//       }
//   return (
//     <>
//         <PaymentForm 
//             applicationId={applicationId}
//             locationId={locationId}
//             cardTokenizeResponseReceived={cardTokenizeResponseReceived}
//         >
//             <CreditCard/>
//         </PaymentForm >
//     </>
//   )
// }

// export default SquareUpPayment

import * as React from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

const MyPaymentForm = (props) => {
  const {paymentInfo,setPaymentInfo,cardTokenizeResponseReceived} = props;
 

return(
  <PaymentForm
  applicationId="sandbox-sq0idb-Mn0Wxpo9UUF6ngJcD-B-SQ"
  cardTokenizeResponseReceived={cardTokenizeResponseReceived}

  // createVerificationDetails={() => ({
  //   amount: '1.00',
  //   /* collected from the buyer */
  //   billingContact: {
  //     addressLines: ['123 Main Street', 'Apartment 1'],
  //     familyName: 'Doe',
  //     givenName: 'John',
  //     countryCode: 'GB',
  //     city: 'London',
  //   },
  //   currencyCode: 'GBP',
  //   intent: 'CHARGE',
  // })}

  locationId="LFZ9VY40TPXFQ"
>
  <CreditCard />
</PaymentForm>
)
}

export default MyPaymentForm;