import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Baconwrappedchickenwithparmesancheese,
  cart1,
  Creamyfarfalleaugratin,
  notFound,
} from "../../constant";
import { GetMainCourseByStarter } from "../../network/Network";
import Skeleton from "react-loading-skeleton";

function MainCourseModal(props) {
  const {
    setIsOpenModalMainCouse,
    isOpenModalMainCouse,
    setIsOpenModalCourseDetail,
    selectedDish,
    selectedCourse,
    setSelectedCourse,
  } = props;

  const [mainCourseData, setMainCourseData] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);

  const ModalViewHandler = (e, item) => {
    e.preventDefault();
    setIsOpenModalMainCouse(false);
    setIsOpenModalCourseDetail(true);
    setSelectedCourse(item);
  };

  useEffect(() => {
    setSpinLoading(true);
    let data = {
      store_id: selectedDish?.id,
    };
    GetMainCourseByStarter(data)
      .then((res) => {
        console.log("main course", res);
        setMainCourseData(res?.data?.data?.list);
        setSpinLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoading(false);
      });
  }, [selectedDish]);

  return (
    <>
      <Modal
        isOpen={isOpenModalMainCouse}
        toggle={() => {
          setIsOpenModalMainCouse(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalMainCouse(false)}
        />
        <div id="MaincourseModal">
          <div className="modal-body">
            <div className="cart-content">
              <div className="heading-wrapper">
                <h2>MENU </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Augue neque gravida in fermentum et sollicitudin ac. Magna ac
                  placerat vestibulum lectus mauris ultrices. Nec feugiat nisl
                  pretium fusce id. Sed risus pretium quam vulputate.
                </p>
              </div>
              <div className="all-courses">
                <div className="courses">
                  <div className="title-wrapper">
                    <h2>MAIN COURSE</h2>
                  </div>
                  <div className="row">
                    {spinLoading ? (
                      <div className="row">
                        <div className="col-md-5 mt-5">
                          <Skeleton count={5} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {mainCourseData?.length > 0 ? (
                          <>
                            {mainCourseData?.map((item, index) => {
                              return (
                                <div
                                  className="course-flex col-lg-4"
                                  key={index}
                                >
                                  <div className="course-box">
                                    <button
                                      type="button"
                                      onClick={(e) => ModalViewHandler(e, item)}
                                    >
                                      <div className="img_box">
                                        <figure>
                                          <img
                                            src={`${item?.image_path}/${item?.image}`}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </figure>
                                        <div className="price">
                                          <h4>${item?.front_sale_price}</h4>
                                        </div>
                                      </div>
                                    </button>
                                    <div className="content">
                                      <h3>{item?.name}</h3>
                                      <p>{item?.description}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="notFound-container">
                            <img
                              src={notFound}
                              alt="not found"
                              className="image-fluid"
                            />
                            <p className="mt-4">No Record Found</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
</div> */}
      </Modal>
    </>
  );
}

export default MainCourseModal;
