import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header.js/Header";
import "../assets/css/menu.css";
import "../assets/css/checkout.css";
import "../assets/css/course.css";
import "../assets/css/Maincourse.css";
import "../assets/css/product-detail.css";
import "../assets/css/MainMenu.css";
import { smallDish, world } from "../constant";
import MenuModal from "../components/Modal/MenuModal";
import ContactModal from "../components/Modal/ContactModal";
import ItemDetailModal from "../components/Modal/ItemDetailModal";
import MainCourseModal from "../components/Modal/MainCourseModal";
import CartModal from "../components/Modal/CartModal";
import CheckoutModal from "../components/Modal/CheckoutModal";
import {
  GetAllCartData,
  GetAllCountries,
  GetStoresByCountry,
} from "../network/Network";
import { CountriesData } from "../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import AllFlags from "../components/Flags/AllFlags";
import HomeAllDish from "../components/Dish/HomeAllDish";
import MainCourseDetailModal from "../components/Modal/MainCourseDetailModal";
import ReactGA from "react-ga";
import AllMenuModal from "../components/Modal/AllMenuModal";
import AllMenuDetails from "../components/Modal/AllMenuDetails";
import GlobeMap from "../components/Map/GlobeMap";
import ContinueModal from "../components/Modal/ContinueModal";
import ButtonWithSpin from "../components/Checkglobal";
import GlobeComponent from "../components/Map/Globe";

const Home = () => {
  const dispatch = useDispatch();
  const User_id = localStorage.getItem("user_id");
  const cartDataRedux = useSelector((state) => state.AuthReducer.cartData);
  const CountriesReduxData = useSelector(
    (state) => state.AuthReducer.countriesData
  );
  const [isOpenModalMenu, setIsOpenModalMenu] = useState(false);
  const [isOpenModalContact, setIsOpenModalContact] = useState(false);
  const [isOpenModalMenuDetail, setIsOpenModalMenuDetail] = useState(false);
  const [isOpenModalMainCouse, setIsOpenModalMainCouse] = useState(false);
  const [isOpenModalCart, setIsOpenModalCart] = useState(false);
  const [isOpenModalCheckout, setIsOpenModalCheckout] = useState(false);
  const [isOpenModalCourseDetail, setIsOpenModalCourseDetail] = useState(false);

  // ---new--
  const [isOpenModalAllMenu, setIsOpenModalAllMenu] = useState(false);
  const [isOpenModalAllMenuDetails, setIsOpenModalAllMenuDetails] =
    useState(false);

  const [isOpenModalContinue, setIsOpenModalContinue] = useState(false);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedDish, setSelectedDish] = useState();
  const [dishData, setDishData] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  const [cartData, setCartData] = useState([]);
  const [flagSelect, setFlagSelect] = useState(null);

  const [meal_id, setMeal_id] = useState();
  const [editFilterMeal, setEditFilterMeal] = useState();
  const [editStatusMeal, setEditStatusMeal] = useState(false);

  const [isShown, setIsShown] = useState(false)
  const [hoverId, setHoverId] = useState()

  const [selectedStarter, setSelectedStarter] = useState();
  const [selectedMainCourse, setSelectedMainCourse] = useState();
  


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    GetAllCountries()
      .then((res) => {
        console.log(res);
        dispatch(CountriesData(res?.data?.data?.list));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let data = {
      user_id: User_id,
    };
    GetAllCartData(data)
      .then((res) => {
        // console.log("CART DATA", res);
        setCartData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    User_id,
    isOpenModalCart,
    isOpenModalAllMenu,
    isOpenModalCheckout,
  ]);
  const options = {
    ambientLightColor: "red",
    cameraRotateSpeed: 0.5,
    focusAnimationDuration: 2000,
    focusEasingFunction: ["Linear", "None"],
    pointLightColor: "gold",
    pointLightIntensity: 1.5,
    globeGlowColor: "blue",
    markerTooltipRenderer: (marker) => `${marker.city} (${marker.value})`,
  };

  const HandleFlagsClick = (e,item) => {
    e.preventDefault()
    setIsOpenModalAllMenu(true);
    // setSelectedCountry(item?.id);
    setFlagSelect(item?.id);
  };


  return (
    <>
    
       <div className="flags-cont custom-points-head">
                {CountriesReduxData?.map((item, index) => {
                  return (
                   <>
                     <div
                      className={`${
                        flagSelect == item?.id
                          ? "single-img single-img-home active-flag"
                          : "single-img single-img-home"
                      }`}
                      key={index}
                      onClick={(e) =>HandleFlagsClick(e,item)}
                      onMouseEnter={() => {setHoverId(item?.id);setIsShown(true)}}
                      onMouseLeave={() => {setHoverId("");setIsShown(false)}}
                    >
                       {
                        item?.id == hoverId ? (
                          <p className={`${isShown ? "show-count" : "hide-cont"}`}>{item?.name}</p>
                        ) : (null)
                      }
                      <img
                        src={`${item?.image_path}/${item?.image}`}
                        className="image-fluid"
                      />
                     
                       
                    
                    </div>
                   
                   
                   </>
                  );
                })}
              </div>
      <Header
        setIsOpenModalContact={setIsOpenModalContact}
        isOpenModalContact={isOpenModalContact}
        setIsOpenModalMenu={setIsOpenModalMenu}
        isOpenModalMenu={isOpenModalMenu}
        setIsOpenModalCart={setIsOpenModalCart}
        cartData={cartData}
        setIsOpenModalAllMenuDetails={setIsOpenModalAllMenuDetails}
      />

      <section className="banner-sec">
        <div
        // className="container"
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="all-dish-wrapper after-menu-show">
                <HomeAllDish
                  setIsOpenModalMenu={setIsOpenModalMenu}
                  dishData={dishData}
                  setSelectedDish={setSelectedDish}
                />
              </div>

              <div className="world-wrapper">
                <div className="world-img-wrapper">
                  {/* <GlobeMap
                    setSelectedCountry={setSelectedCountry}
                    flagSelect={flagSelect}
                    // options={options}
                    setFlagSelect={setFlagSelect}
                    setIsOpenModalAllMenu={setIsOpenModalAllMenu}
                  /> */}

                  <GlobeComponent
                    setSelectedCountry={setSelectedCountry}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}
                    setIsOpenModalAllMenu={setIsOpenModalAllMenu}
                  />

                  {/* <img
                    src={world}
                    className="img-fluid wow animate__animated animate__zoomInDown"
                    alt=""
                    onClick={()=>setFlagSelect(null)}
                  /> */}
                </div>
                {/* <AllFlags 
                    CountriesReduxData={CountriesReduxData}
                    setSelectedCountry={setSelectedCountry}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}

                    setIsOpenModalAllMenu={setIsOpenModalAllMenu}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ContactModal
        setIsOpenModalContact={setIsOpenModalContact}
        isOpenModalContact={isOpenModalContact}
      />

      {/* <MenuModal
        setIsOpenModalMenu={setIsOpenModalMenu}
        isOpenModalMenu={isOpenModalMenu}
        setIsOpenModalMenuDetail={setIsOpenModalMenuDetail}
        setSelectedDish={setSelectedDish}
        selectedDish={selectedDish}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      /> */}

      {/* <ItemDetailModal
        setIsOpenModalMenuDetail={setIsOpenModalMenuDetail}
        isOpenModalMenuDetail={isOpenModalMenuDetail}
        setIsOpenModalMainCouse={setIsOpenModalMainCouse}
        setIsOpenModalCart={setIsOpenModalCart}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      /> */}

      {/* <MainCourseModal
        setIsOpenModalMainCouse={setIsOpenModalMainCouse}
        isOpenModalMainCouse={isOpenModalMainCouse}
        setIsOpenModalCourseDetail={setIsOpenModalCourseDetail}
        selectedDish={selectedDish}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
      />

      <MainCourseDetailModal
        setIsOpenModalCourseDetail={setIsOpenModalCourseDetail}
        isOpenModalCourseDetail={isOpenModalCourseDetail}
        setIsOpenModalMainCouse={setIsOpenModalMainCouse}
        setIsOpenModalCart={setIsOpenModalCart}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
      /> */}

      {/* -----new--- */}
      <AllMenuModal
        setIsOpenModalAllMenu={setIsOpenModalAllMenu}
        isOpenModalAllMenu={isOpenModalAllMenu}
        setIsOpenModalAllMenuDetails={setIsOpenModalAllMenuDetails}
        flagSelect={flagSelect}
        setFlagSelect={setFlagSelect}
        CountriesReduxData={CountriesReduxData}
        setEditFilterMeal={setEditFilterMeal}
        editFilterMeal={editFilterMeal}
        setEditStatusMeal={setEditStatusMeal}
        editStatusMeal={editStatusMeal}
        setIsOpenModalContinue={setIsOpenModalContinue}

  
        selectedStarter={selectedStarter} setSelectedStarter={setSelectedStarter}
        selectedMainCourse={selectedMainCourse} setSelectedMainCourse={setSelectedMainCourse}
      />

      <ContinueModal
        isOpenModalContinue={isOpenModalContinue}
        setIsOpenModalContinue={setIsOpenModalContinue}
        setIsOpenModalAllMenuDetails={setIsOpenModalAllMenuDetails}
        flagSelect={flagSelect}
        setFlagSelect={setFlagSelect}
        CountriesReduxData={CountriesReduxData}

        selectedStarter={selectedStarter} setSelectedStarter={setSelectedStarter}
        selectedMainCourse={selectedMainCourse} setSelectedMainCourse={setSelectedMainCourse}
      />

      <AllMenuDetails
        setIsOpenModalAllMenuDetails={setIsOpenModalAllMenuDetails}
        isOpenModalAllMenuDetails={isOpenModalAllMenuDetails}
        setIsOpenModalCheckout={setIsOpenModalCheckout}
        cartData={cartData}
        setCartData={setCartData}
        setIsOpenModalAllMenu={setIsOpenModalAllMenu}
        setMeal_id={setMeal_id}
        meal_id={meal_id}
        setEditFilterMeal={setEditFilterMeal}
        editFilterMeal={editFilterMeal}
        setEditStatusMeal={setEditStatusMeal}
        editStatusMeal={editStatusMeal}
      />
      {/* ----new------ */}
      <CartModal
        setIsOpenModalCart={setIsOpenModalCart}
        isOpenModalCart={isOpenModalCart}
        setIsOpenModalCheckout={setIsOpenModalCheckout}
        cartData={cartData}
        setCartData={setCartData}
      />

      <CheckoutModal
        setIsOpenModalCheckout={setIsOpenModalCheckout}
        isOpenModalCheckout={isOpenModalCheckout}
        selectedDish={selectedDish}
        cartData={cartData}
        setCartData={setCartData}
        flagSelect={flagSelect}
      />
    </>
  );
};

export default Home;
