import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { notFound, smallDish } from "../../constant";
import {
  AddToCart,
  GetAllCartData,
  GetProductsByCountry,
  UpdateCart,
} from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { set } from "react-ga";
import { AiFillCheckCircle, AiFillCaretDown } from "react-icons/ai";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { SpinnerCircular } from "spinners-react";
import { CartData } from "../../redux/actions/AuthActions";

function ContinueModal(props) {
  const {
    setIsOpenModalContinue,
    isOpenModalContinue,
    setIsOpenModalAllMenuDetails,
    flagSelect,
    setFlagSelect,
    CountriesReduxData,
    setEditFilterMeal,
    editFilterMeal,
    setEditStatusMeal,
    editStatusMeal,

    selectedStarter, setSelectedStarter,
        selectedMainCourse, setSelectedMainCourse,
  } = props;
  const cartDataRedux = useSelector((state) => state.AuthReducer.cartData);

  const dispatch = useDispatch()
  const User_id = localStorage.getItem("user_id");

  const [cartData, setCartData] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);
  const [hoverCol, setHoverCol] = useState(false);
  const [hideShow, setHideShow] = useState("");

  const [starterData, setStarterData] = useState([]);
  const [mainCourseData, setMainCourseData] = useState([]);

  const [countryName, setCountryName] = useState();
  const [count, setCount] = useState(0);


  const ContinueHandler = (e) => {
    e.preventDefault();
    setIsOpenModalContinue(false);
    setIsOpenModalAllMenuDetails(true);
  };

  


  // console.log("c meal", cartDataRedux);

  return (
    <>
      <Modal
        isOpen={isOpenModalContinue}
        toggle={() => {
            setIsOpenModalContinue(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalContinue(false)}
        />

        <div id="courseModal">
          <div className="modal-body">
            <div className="AllMenu-Main-container cart-content">
              <div className="heading-wrapper">
                <h2 className="main-title">
                  {countryName ? countryName[0]?.name : null}
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
              </div>
              <div className="cart-data">
                <div className="text-cont">
                  <p>Items Added</p>
                </div>

{
  cartDataRedux?.cart?.length > 0 ? (
<>
<div>
                    <img src={`${cartDataRedux?.cart[0]?.starter?.image_path}/${cartDataRedux?.cart[0]?.starter?.image}`} alt="" className="image-fluid mr-2" />
                  </div>

              
                  <div>
                    <img src={`${cartDataRedux?.cart[0]?.main_course?.image_path}/${cartDataRedux?.cart[0]?.main_course?.image}`} alt="" className="image-fluid" />
                  </div>
</>
  ) : (null)
}
               

              </div>
              <div className="flags-cont">
                {CountriesReduxData?.map((item, index) => {
                  return (
                    <div
                      className={`${
                        flagSelect == item?.id
                          ? "single-img active-flag"
                          : "single-img"
                      }`}
                      key={index}
                      onClick={() => setFlagSelect(item?.id)}
                    >
                      <img
                        src={`${item?.image_path}/${item?.image}`}
                        className="image-fluid"
                      />
                    </div>
                  );
                })}
              </div>

              <div className="selected-items-cont">
               
                 {
                cartDataRedux?.cart?.length ? (
                    <>
                       <div
                    className="selected-items"
                  >
                    <span className="mr-4">Entree</span>
                    <img  src={`${cartDataRedux?.cart[0]?.starter?.image_path}/${cartDataRedux?.cart[0]?.starter?.image}`} alt="" className="image-fluid mr-2" />
                  
                  </div>
               
                  <div
                    className="selected-items"
                  >
                    <span className="mr-4"> Main Course</span>
                    <img src={`${cartDataRedux?.cart[0]?.main_course?.image_path}/${cartDataRedux?.cart[0]?.main_course?.image}`} alt="" className="image-fluid" />
                   
                  </div>
                    </>
                  ): (null)
                 }
              
              </div>
             
             
              <div className="row">
                <div className="col-lg-12 btn-container">
                <button
                            className="btn addtocart-Btn mr-5"
                            onClick={(e) => setIsOpenModalContinue(false)}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            Add Another Meal
                          </button>
                          <button
                            className="btn addtocart-Btn"
                            onClick={(e) => ContinueHandler(e)}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                            Go to Cart
                          </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ContinueModal;
