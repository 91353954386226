import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { cart1 } from "../../constant";
import MyPaymentForm from "../../components/PaymentMethod/SquareUpPayment";
import { CheckOut } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { dispatch } from "d3";
import { CartData } from "../../redux/actions/AuthActions";

function CheckoutModal(props) {
  const {
    setIsOpenModalCheckout,
    isOpenModalCheckout,
    selectedDish,
    flagSelect,
  } = props;

  const cartDataRedux = useSelector((state) => state.AuthReducer.cartData);
  const User_id = localStorage.getItem("user_id");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [stateUser, setStateUser] = useState("");
  const [zip_code, setZip_code] = useState("");

  const [paymentInfo, setPaymentInfo] = useState();
  const [loading, setLoading] = useState(false);

  const cardTokenizeResponseReceived = (token, buyer) => {
    // console.info({ token, buyer });
    setPaymentInfo(token?.token);
    console.log(paymentInfo);
  };

  const CheckOutSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !address ||
      !address2 ||
      !city ||
      !country ||
      !stateUser ||
      !zip_code 
    
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (phone?.length < 8 || phone?.length > 20) {
      toast.error("The phone must be between 8 to 20 digits.");
      setLoading(false);
      return;
    }
    if (!paymentInfo) {
      toast.error("Please pay Before Checkout");
      setLoading(false);
      return;
    }
    let data = {
      country_id: flagSelect,
      user_id: User_id,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      address: address,
      address2: address2,
      city: city,
      country: country,
      state: stateUser,
      zip_code: zip_code,
      //  card_nonce: "card_nonce",
      source_id: paymentInfo,
      // store_id: StoreDataRedux?.id,
    };
    CheckOut(data)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        toast.success("Successfully Order Submit.");
        setIsOpenModalCheckout(false);
        let val = [];
        dispatch(CartData(val))
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        address("");
        address2("");
        city("");
        country("");
        stateUser("");
        setZip_code("");
        paymentInfo("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpenModalCheckout}
        toggle={() => {
          setIsOpenModalCheckout(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalCheckout(false)}
        />

        <div id="checkoutModal">
          <div className="modal-body">
            <div className="cart-content">
              <div className="heading-wrapper">
                <h2>ORDER DETAILS</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Augue neque gravida in fermentum et sollicitudin ac.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <div className="shipping">
                    <form action="">
                      <div className="row">
                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Shipment Method</label>
                                                <select className="form-control">
                                                    <option>Select Shipment method</option>
                                                    <option>Method1</option>
                                                    <option>Method2</option>
                                                    <option>Method3</option>
                                                </select>
                                            </div>
                                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              type="number"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Suite, Apartment, etc </label>
                            <input
                              type="text"
                              className="form-control"
                              value={address2}
                              onChange={(e) => setAddress2(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              className="form-control"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Country/Region</label>
                            <input
                              type="text"
                              className="form-control"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              className="form-control"
                              value={stateUser}
                              onChange={(e) => setStateUser(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input
                              type="number"
                              className="form-control"
                              value={zip_code}
                              onChange={(e) => setZip_code(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="title">
                            <h4>Payment Details</h4>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <MyPaymentForm
                              setPaymentInfo={setPaymentInfo}
                              paymentInfo={paymentInfo}
                              cardTokenizeResponseReceived={
                                cardTokenizeResponseReceived
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="right-side">
                    <div className="heading">
                      <h2>ORDER DETAILS</h2>
                    </div>

                    <div className="cart-products">
                      {cartDataRedux?.cart?.map((item, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col-lg-6 cart-box">
                              <div className="img-box">
                                <figure>
                                  <img
                                    src={`${item?.starter?.image_path}/${item?.starter?.image}`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </figure>
                              </div>
                              <div className="txt">
                                <h4>{item?.starter?.name}</h4>
                                <p>{item?.starter?.description}</p>
                                <h4 className="price">
                                  <span>PRICE :</span>${item?.starter?.price}
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-6 cart-box">
                              <div className="img-box">
                                <figure>
                                  <img
                                    src={`${item?.main_course?.image_path}/${item?.main_course?.image}`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </figure>
                              </div>
                              <div className="txt">
                                <h4>{item?.main_course?.name}</h4>
                                <p>{item?.main_course?.description}</p>
                                <h4 className="price">
                                  <span>PRICE :</span>$
                                  {item?.main_course?.price}
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="order-summary">
                      <div className="summary">
                        <div className="title">
                          <h2>ORDER SUMMARY</h2>
                        </div>
                        <ul className="charges">
                          <li>
                            <span>DELIVERY CHARGES :</span>$
                            {cartDataRedux?.delivery_fee}
                          </li>
                          <li>
                            <span>SUBTOTAL :</span>${cartDataRedux?.subtotal}
                          </li>
                          <li>
                            <span>Total :</span>${cartDataRedux?.total}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-bar">
                <div className="left">
                  {/* <input type="checkbox" id="prvcy" className="form-control" />
                            <label for="prvcy">Lorem ipsum dolor sit amet consectetur adipisicing</label> */}
                </div>
                <div className="right">
                  <button
                    className="btn"
                    disabled={loading}
                    onClick={(e) => CheckOutSubmit(e)}
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    {loading ? "Loading..." : "SUBMIT"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
</div> */}
      </Modal>
    </>
  );
}

export default CheckoutModal;
