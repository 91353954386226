import React, { useEffect, useState } from "react";
import Globe from "react-globe.gl";
import number from "numeral";
import chroma from "chroma-js";
import { useSelector } from "react-redux";

export default function GlobeComponent(props) {
  const {
    setSelectedCountry,
    flagSelect,
    setFlagSelect,
    setIsOpenModalAllMenu,
  } = props;
  const CountriesReduxData = useSelector(
    (state) => state.AuthReducer.countriesData
  );
  const [custMarker, setCustMarker] = useState();
  const [selectState, setSelectState] = useState();

  const [hoverD, setHoverD] = React.useState();
  const globeEl = React.useRef();
  const [globeData, setGlobeData] = React.useState({
    countries: {
      features: [],
    },
    points: {
      features: [],
    },
  });

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const colorScale = chroma.scale(["red", "green"]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        fetch("./correctedData.json")
          .then((response) => response.json())
          .then((data) => {
            const sortedData = data.sort((a, b) =>
              a.countryName.localeCompare(b.countryName)
            );
            // console.log(sortedData);
            setData(sortedData);
          });

        fetch(
          "https://raw.githubusercontent.com/iamanas20/geojson/main/map11.geojson"
        )
          .then((res) => res.json())
          .then(function (res) {
            console.log(res);
            setGlobeData({
              countries: res[0],
              points: res[1],
            });
          });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  React.useEffect(
    function () {
      if (globeEl.current !== undefined) {
        const scene = globeEl.current.scene();
        if (scene.children.length === 4) {
          scene.children[1].intensity = 1.5;
          scene.children[2].visible = false;
        }
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.5;
        globeEl.current.controls().enableZoom = true;
        globeEl.current.controls().minZoom = 1000;
        globeEl.current.controls().enableZoom = false
        // globeEl.current.controls().object.zoom = 1.5;
        globeEl.current.controls().zoom0 = 10;
        
        globeEl.current.controls().minDistance = 101;
        globeEl.current.controls().maxDistance = 280;
        
        // console.log("gblinner", globeEl.current.controls())
        // globeEl.current.controls().width = "1000px";
        // globeEl.current.controls().height = "1000px";
      }
    },
    [globeData]
  );

  let lookup = [];
  
  useEffect(() => {
    let i;
    let Newmarker = [];
    for (i = 0; i < CountriesReduxData.length; i++) {
      Newmarker.push({
        markerOffset: 0,
        name: CountriesReduxData[i]?.name,
        coordinates: [
          CountriesReduxData[i]?.latitude,
          CountriesReduxData[i]?.longitude,
        ],
        id: CountriesReduxData[i]?.id,
        image: CountriesReduxData[i]?.image,
      });
    }
    // console.log("marker ", Newmarker);
    setCustMarker(Newmarker);
  }, []);

  // console.log("marker tt ", custMarker);

  const markers = [
    // lng lat
    { markerOffset: 0, name: "Pakistan", coordinates: [69.345116, 30.375321] },
    { markerOffset: 0, name: "Italy", coordinates: [12.56738, 41.87194] },
    {
      markerOffset: 0,
      name: "Australia",
      coordinates: [133.775136, -25.274398],
    },
    { markerOffset: 0, name: "Belgium", coordinates: [4.469936, 50.503887] },
    { markerOffset: 0, name: "Belize", coordinates: [-88.4976, 17.1899] },
    { markerOffset: 0, name: "Canada", coordinates: [-106.346771, 56.130366] },
    { markerOffset: 0, name: "China", coordinates: [104.195397, 35.86166] },
    { markerOffset: 0, name: "Denmark", coordinates: [9.501785, 56.26392] },
    { markerOffset: 0, name: "France", coordinates: [2.213749, 46.227638] },
    { markerOffset: 0, name: "Germany", coordinates: [10.451526, 51.165691] },
    { markerOffset: 0, name: "India", coordinates: [78.96288, 20.593684] },
    {
      markerOffset: 0,
      name: "Indonesia",
      coordinates: [113.921327, -0.789275],
    },
    { markerOffset: 0, name: "Japan", coordinates: [138.252924, 36.204824] },
    { markerOffset: 0, name: "Thailand", coordinates: [100.992541, 15.870032] },
    { markerOffset: 0, name: "Turkey", coordinates: [35.243322, 38.963745] },
    {
      markerOffset: 0,
      name: "United Kingdom",
      coordinates: [-3.435973, 55.378051],
    },
    { markerOffset: 0, name: "Zimbabwe", coordinates: [29.154857, -19.015438] },
    {
      markerOffset: 0,
      name: "Antartica",
      coordinates: [-0.071389, -75.250973],
    },
    { markerOffset: 0, name: "Benin", coordinates: [2.315834, 9.30769] },
    { markerOffset: 0, name: "Bermuda", coordinates: [-64.75737, 32.321384] },
  ];

  const handleClick = (e) => {
    setIsOpenModalAllMenu(true);
    setSelectState(e?.name);
    setSelectedCountry(e);
    setFlagSelect(e?.id);
  };

  // console.log(window.innerWidth,window.innerHeight)

  return (
    <div
      className=""
      onMouseEnter={(e)=>globeEl.current.controls().autoRotate = false}
      onMouseLeave={(e)=>globeEl.current.controls().autoRotate = true}
    >
      {loading && <div>Loading...</div>}
      {!loading && (
        <Globe
          width={(window.innerWidth ) + 'px'}
          height={(window.innerHeight) + 'px'}
          ref={globeEl}
          backgroundColor="#f0f8ff00"
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
          // backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
          showAtmosphere={true}
          // polygonsData={globeData.countries.features}
          polygonStrokeColor={() => "#3E4850"}
          polygonSideColor={() => "rgba(222,225,228,.6)"}
          // onPolygonHover={setHoverD}
          // polygonCapColor={markers?.map((item,index)=>{
          // 	return colorScale(1)
          // 			.brighten(0.5)
          // 	 		.hex();
          // })}
          // polygonCapColor={function ({ properties: d }) {
          // 	for (let i = 0, len = data.length; i < len; i++) {
          // 		lookup[data[i].countryName] = data[i];
          // 	}
          // 	return colorScale(lookup[d.ADMIN]?.happinessScore * 0.1)
          // 		.brighten(0.5)
          // 		.hex();
          // }}
          polygonCapColor={function ({ properties: d }) {
            for (let i = 0, len = custMarker.length; i < len; i++) {
              lookup[custMarker[i].name] = custMarker[i];
            }
            return colorScale(lookup[d.ADMIN]?.happinessScore * 0.1)
              .brighten(0.5)
              .hex();
          }}
          polygonLabel={function ({ properties: d }) {
            for (let i = 0, len = custMarker.length; i < len; i++) {
              lookup[custMarker[i].name] = custMarker[i];
            }

            return `
				<div style="position: relative; z-index: 4; min-width: 108px; padding: 10px 14px;background: #fff;border: 1px solid #E5E5E5;box-shadow: 0px 2px 20px rgba(32, 32, 35, 0.13);border-radius: 4px; text-align: left;">
				<div style="font-family: 'Open sans', sans-serif; margin-bottom:10px;font-weight: 600;font-size: 13px;line-height: 16px;text-transform: capitalize;color: #2D3032;">
					${d.name}
				</div>
					
				</div>
			`;
          }}
          // 	polygonLabel={function ({ properties: d }) {
          // 		for (let i = 0, len = data.length; i < len; i++) {
          // 			lookup[data[i].countryName] = data[i];
          // 		}

          // 		return `
          // 	<div style="position: relative; z-index: 4; min-width: 108px; padding: 10px 14px;background: #fff;border: 1px solid #E5E5E5;box-shadow: 0px 2px 20px rgba(32, 32, 35, 0.13);border-radius: 4px; text-align: left;">
          // 	<div style="font-family: 'Open sans', sans-serif; margin-bottom:10px;font-weight: 600;font-size: 13px;line-height: 16px;text-transform: capitalize;color: #2D3032;">
          // 		${d.ADMIN}
          // 	</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Visitors: ${number(d.POP_EST).format("0a")}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Happiness Score: ${lookup[d.ADMIN]?.happinessScore}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Happiness Rank: ${lookup[d.ADMIN]?.happinessRank}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Life Expectancy: ${
          // 											lookup[d.ADMIN]?.healthLifeExpectancy
          // 										}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Generosity: ${lookup[d.ADMIN]?.generosity}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			Freedom: ${lookup[d.ADMIN]?.freedom}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			TrustGovernmentCorruption: ${
          // 											lookup[d.ADMIN]?.trustGovernmentCorruption
          // 										}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			DystopiaResidual: ${
          // 											lookup[d.ADMIN]?.dystopiaResidual
          // 										}
          // 		</div>
          // 		<div style="font-family: 'Open sans', sans-serif;font-size: 13px;line-height: 16px;color: #3E4850;">
          // 			DataYear: ${lookup[d.ADMIN]?.year}
          // 		</div>

          // 	</div>
          // `;
          // 	}}
          labelsData={custMarker}
          // labelsData={(markers)=> }
          labelLat={(d) => d.coordinates[0]}
          labelLng={(d) => d.coordinates[1]}
          // labelAltitude={(d) => (d.properties.type === "order" ? 0.015 : 0.013)}
          labelText={(d) => d.name}
          labelSize={(d) => 1}
          labelDotRadius={(d) => 1}
          // labelColor="#5A68BD"
          // labelColor={(d) =>
          // 	d.properties.type === "order" ? "#5A68BD" : "#51CB90"
          // }
          labelResolution={2}
          onLabelClick={(e) => handleClick(e)}
          // onPolygonHover={(e)=>console.log("run")}
        />
      )}
    </div>
  );
}
