import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";


function PublicRoutes(props) {
  const { unityContext } = props;
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          
          
         
          <Route path="/*" element={<p>not Found</p>} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
