import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  notFound,
  user,
} from "../../constant";
import {
  AddToCart,
  GetAllCartData,
  GETMenuDetails,
  GetProductByStore,
  PostReviews,
  RemoveById,
} from "../../network/Network";
import { toast } from "react-toastify";
import { CartData, UserIdStore } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { set } from "react-ga";
import { RiChatDeleteFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function AllMenuDetails(props) {
  const {
    setIsOpenModalAllMenuDetails,
    isOpenModalAllMenuDetails,
    setIsOpenModalCheckout,
    setCartData,
    cartData,
    setIsOpenModalAllMenu,
    setMeal_id,
    meal_id,
    setEditFilterMeal,
    editFilterMeal,
    setEditStatusMeal,
    editStatusMeal,
  } = props;
  const dispatch = useDispatch();
  const User_id = localStorage.getItem("user_id");
  const [menuDetail, setMenuDetail] = useState();
  const [hoverCol, setHoverCol] = useState(false);

  const [spinLoad, setSpinLoad] = useState(false);
  const [remove, setRemove] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState();
  const [rBtLoading, setRBtLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [spinLoading, setSpinLoading] = useState(false);

  useEffect(() => {
    setSpinLoading(true);
    let data = {
      id: selectedDetails,
    };
    GETMenuDetails(data)
      .then((res) => {
        // console.log(res);
        setMenuDetail(res?.data?.data);
        setSpinLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoading(false);
      });
  }, [selectedDetails]);

  useEffect(() => {
    setSpinLoad(true);
    let data = {
      user_id: User_id,
    };
    GetAllCartData(data)
      .then((res) => {
        // console.log("CART DATA", res);
        setCartData(res?.data?.data);
        dispatch(CartData(res?.data?.data));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [User_id, isOpenModalAllMenuDetails, remove]);

  const ProceedHandler = (e) => {
    e.preventDefault(e);
    setIsOpenModalAllMenuDetails(false);
    setIsOpenModalCheckout(true);
  };

  const RemoveCartIdHandler = (e, id) => {
    e.preventDefault();
    let data = {
      cart_id: id,
      user_id: User_id,
    };
    RemoveById(data)
      .then((res) => {
        // console.log(res);
        toast.success("Meal Successfully Remove");
        setRemove(!remove);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SubmitReview = (e) => {
    e.preventDefault();
    setRBtLoading(true);
    if (!comment) {
      toast.error("Please Enter Your Comments");
      setRBtLoading(false);
      return;
    }
    let data = {
      product_id: selectedDetails ? selectedDetails :  cartData?.cart[0]?.starter?.id,
      comment: comment,
    };
    PostReviews(data)
      .then((res) => {
        setRBtLoading(false);
        // console.log(res);
        toast.success("Successfully send");
        setComment("");
      })
      .catch((err) => {
        setRBtLoading(false);
        console.log(err);
        setComment("");
      });
  };

  const EditMealHandler = (e) => {
    e.preventDefault();
    setIsOpenModalAllMenu(true);
    setIsOpenModalAllMenuDetails(false);
    // console.log(menuDetail);
    let filterEditData = cartData?.cart?.filter((item) => item?.id == meal_id);
    setEditFilterMeal(filterEditData);
    // console.log("edit ff ", filterEditData);
    setEditStatusMeal(!editStatusMeal);
  };



  return (
    <>
      <Modal
        isOpen={isOpenModalAllMenuDetails}
        toggle={() => {
          setIsOpenModalAllMenuDetails(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalAllMenuDetails(false)}
        />

        <div id="courseModal">
          <div className="modal-body">
            <div className="AllMenu-Details-container cart-content">
              <div className="row">
                <div className="col-lg-5">
                  <div className="left-cont">
                    <h3 className="left-title">ITEMS ADDED</h3>
                    <div className="main-banner">
                      {menuDetail ? (
                        <>
                          <img
                            src={`${menuDetail?.image_path}/${menuDetail?.image}`}
                            className="image-fluid"
                            alt=""
                          />
                          <AiOutlineRollback
                            className="edit-meal-icon"
                            onClick={(e) => EditMealHandler(e)}
                          />
                        </>
                      ) : (
                        <>
                          {cartData?.cart ? (
                            <img
                              src={`${cartData?.cart[0]?.starter?.image_path}/${cartData?.cart[0]?.starter?.image}`}
                              className="image-fluid"
                              alt=""
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                    <div className="row all-images">
                      {cartData?.cart?.map((item, index) => {
                        return (
                          <>
                            <div className="col-lg-3">
                              <div
                                className="small-banner"
                                key={index}
                                onClick={() => {
                                  setSelectedDetails(item?.starter?.id);
                                  setMeal_id(item?.id);
                                }}
                              >
                                <img
                                  src={`${item?.starter?.image_path}/${item?.starter?.image}`}
                                  className="image-fluid"
                                  alt=""
                                />
                              </div>
                              <RiChatDeleteFill
                                className="remove-item"
                                onClick={(e) =>
                                  RemoveCartIdHandler(e, item?.id)
                                }
                              />
                            </div>

                            <div className="col-lg-3">
                              <div
                                className="small-banner"
                                onClick={() => {
                                  setSelectedDetails(item?.main_course?.id);
                                  setMeal_id(item?.id);
                                }}
                              >
                                <img
                                  src={`${item?.main_course?.image_path}/${item?.main_course?.image}`}
                                  className="image-fluid"
                                  alt=""
                                />
                              </div>
                              <RiChatDeleteFill
                                className="remove-item"
                                onClick={(e) =>
                                  RemoveCartIdHandler(e, item?.id)
                                }
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="left-cont-summary">
                    <p className="order-summary-title">ORDER SUMMARY</p>
                    <p className="sub-text">
                      DELIVERY CHARGES : <span>${cartData?.delivery_fee}</span>
                    </p>
                    <p className="sub-text">
                      SUBTOTAL : <span>${cartData?.subtotal}</span>
                    </p>
                    <p className="para-text">
                      Taxes and discounts will be calculated at checkout
                    </p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="right-cont">
                    <h2 className="main-title">
                      {menuDetail
                        ? menuDetail?.name
                        : cartData?.cart
                        ? cartData?.cart[0]?.starter?.name
                        : null}
                    </h2>
                    <p className="main-para">
                      {menuDetail
                        ? menuDetail?.description
                        : cartData?.cart
                        ? cartData?.cart[0]?.starter?.description
                        : null}
                    </p>
                    <div>
                      <h3 className="md-title">Have You Tried This Item?</h3>
                      <textarea
                        placeholder="Write A Review Here.."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <div className="btn-container">
                        <button
                          className="btn submit-Btn"
                          disabled={rBtLoading}
                          onClick={(e) => SubmitReview(e)}
                        >
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                          {rBtLoading ? "Loading.." : "Submit"}
                        </button>
                      </div>
                      <div className="client-review-wrapper">
                        {spinLoading ? (
                          <div className="row">
                            <div className="col-md-12 mt-5">
                              <Skeleton count={5} />
                            </div>
                          </div>
                        ) : (
                          <>
                            {menuDetail?.reviews?.length > 0 ? (
                              <ul>
                                {menuDetail?.reviews?.map((item, index) => {
                                  return (
                                    <>
                                      {index < 3 ? (
                                        <li key={index}>
                                          <div
                                            className="client-review-content"
                                            key={index}
                                          >
                                            <div className="client-img-wrapper">
                                              <figure>
                                                <img
                                                  src={user}
                                                  className="img-fluid"
                                                  alt=""
                                                />
                                              </figure>
                                            </div>
                                            <div className="client-content-wrapper">
                                              <p>{item?.comment}</p>
                                            </div>
                                          </div>
                                        </li>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div className="notFound-container">
                                <img
                                  src={notFound}
                                  alt="not found"
                                  className="image-fluid"
                                />
                                <p className="mt-4">No Reviews Found</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="btn-container mt-4">
                        <button
                          className="btn addtocart-Btn"
                          onClick={(e) => ProceedHandler(e)}
                          disabled={cartData?.cart?.length > 0 ? false : true}
                        >
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                          PROCEED TO PAY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AllMenuDetails;
