import React from 'react'

const Footer = () => {
  return (
   <>
    <footer className="footer">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="contact-info-wrapper">
                    <ul>
                        <li>
                            <a href="mailto:info@globalresta.net">
                                info@globalresta.net
                            </a>
                        </li>
                        <li>
                            <a href="tel:45545454555">
                                +455 45 454555
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="copyright-wrapper">
                    <p>© COPYRIGHT RESTAURANT 2022</p>
                </div>
            </div>
        </div>
    </div>
</footer>

   </>
  )
}

export default Footer