import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/contact.css";
import { toast } from "react-toastify";
import { PostContactUs } from "../../network/Network";

function ContactModal(props) {
  const { setIsOpenModalContact, isOpenModalContact } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const ContactUsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !message
      // !subject ||
      // !address
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (phone.length < 8) {
      toast.error("The contact number must be at least 10 characters.");
      setLoading(false);
      return;
    }
    if (message.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      name: firstName + lastName,
      subject: subject,
      phone: phone,
      email: email,
      address: address,
      content: message,
    };
    console.log(data);
    PostContactUs(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setAddress("");
        setSubject("");
        setMessage("");
        setIsOpenModalContact(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpenModalContact}
        toggle={() => {
          setIsOpenModalContact(false);
        }}
        className="custom-modal modal-width"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModalContact(false)}
        />

        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="contact-sec-all">
                <div className="contact-head">
                  <h3>CONTACT US</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Augue neque gravida in fermentum et sollicitudin ac.
                    Magna ac.
                  </p>

                  <form action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="Comment Here..."
                            rows="3"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-contact-submit-btn">
                          <button
                            onClick={(e) => ContactUsHandler(e)}
                            disabled={loading}
                            className="btn"
                          >
                            {loading ? "Loading.." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ContactModal;
